<template>
  <div class="BannerDark">
    <div class="BannerDark__content">
      <div class="BannerDark__title text-m32">
        {{ props.title }}
      </div>
      <div class="BannerDark__text text-r16">
        {{ props.text }}
      </div>

      <div
        v-if="props.btn"
        class="BannerDark__btn"
      >
        <MButton
          :size="MM.EButtonSizes.Large"
          :theme="props.btn.theme"
          :icon-left="props.btn.iconLeft"
          :icon-right="props.btn.iconRight"
          :icon-fill-disabled="props.btn.iconFillDisabled"
          @click="if (props.btn.action) props.btn.action();"
        >
          {{ props.btn.label }}
        </MButton>
      </div>
    </div>

    <div
      v-if="props.img"
      class="BannerDark__img"
      @click="if (props.img.action) props.img.action();"
    > 
      <MNuxtImg
        v-if="img"
        :src="img.src"
        :sources="img.sources"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { MM } from '~/types';
import MNuxtImg from '../MNuxtImg/MNuxtImg.vue';
import { INuxtImgSource } from '../MNuxtImg/MNuxtImg.types';

const props = defineProps<{
  title: string;
  text?: string;
  btn?: {
    label?: string;
    theme?: MM.EButtonThemes;
    iconLeft?: MM.EIconPath;
    iconRight?: MM.EIconPath;
    iconFillDisabled?: boolean;
    action?: () => void;
  };
  img?: {
    src: string;
    srcset?: string;
    sources?: INuxtImgSource[];
    action?: () => void;
  };
}>();
</script>

<style lang="scss" scoped>
.BannerDark {
  background: linear-gradient(81.46deg, #02140e 18.86%, #053b3f 73.91%);
  border-radius: 8px;
  padding: 40px var(--container-padding-X);
  position: relative;
  color: white;
  overflow: hidden;

  &__content {
    width: 500px;
    max-width: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    &:after {
      content: '';
      flex-grow: 1;
    }
  }

  &__title {
  }

  &__text {
    margin-top: 32px;
    opacity: 0.6;
  }

  &__btn {
    margin-top: 72px;
    order: 1;
  }

  &__img {
    position: absolute;
    top: 0;
    right: 32px;
    bottom: 0;
    cursor: pointer;
    overflow: hidden;

    :deep(img) {
      max-height: 100%;
    }

    // @include cover;
  }

  @include maxw(1599) {
    &__content {
      width: 400px;
    }
    &__img {
    }
  }
  @include maxw(1279) {
    &__img {
      right: -80px;
      img {
        height: 100%;
      }
    }
  }
  @include maxw(767) {
    padding-top: 24px;
    padding-bottom: 8px;
    &__content {
      width: 100%;
      max-width: 100%;
      // padding-bottom: 200px;
    }
    &__text {
      margin-top: 16px;
    }

    &__img {
      // right: 0;
      // bottom: 0;
      // left: 0;
      // top: auto;
      // height: 200px;

      &:deep(img) {
        width: 100%;
      }

      position: static;
      width: calc(100% + 2 * var(--container-padding-X));
      margin: calc(-1 * var(--container-padding-X));
      margin-top: -48vw;
      line-height: 0;
    }
    &__btn {
      display: none;
    }
  }
}
</style>
