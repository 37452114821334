<template>
  <div class="AboutCompany">
    <div class="AboutCompany__main">
      <div class="AboutCompany__title text-m32">
        B2B-маркетплейс быстрых закупок
      </div>
      <div class="AboutCompany__caption text-r12">
        АО «В2В Трэйд» — ИТ компания, обеспечивающая разработку платформы маркетплейса Максмарт
      </div>
    </div>
    <div class="AboutCompany__description">
      <div
        class="AboutCompany__text"
        :class="{ _expanded: isExpanded }"
      >
        <p>
          B2B-маркетплейс Максмарт – инновационный для российского рынка маркетплейс быстрых корпоративных закупок стандартизованной
          (long-tail) номенклатуры, который оптимизирует закупочный процесс для крупных промышленных, строительных компаний и бизнеса любого
          масштаб а. Преимуществом Максмарт по сравнению с другими электронными торговыми площадками является обеспечение полного цикла
          сделки от получения потребности от покупателя до автоматического формирования корзины на основании мэппинга номенклатуры
          покупателя и маркетплейса, автоматического выставления документов на входящую поставку и поставки в базис получателя.
        </p>

        <p>
          B2B-площадка обеспечивает автоматизацию процесса корпоративных закупок и оцифровывает производителей, обеспечивает консолидацию
          заказов и оптовые поставки до покупателя.
        </p>

        <p>
          Маркетплейс быстрых оптовых закупок Максмарт обеспечивает поставщикам и производителям продукции выход в интернет-пространство и
          доступ к широкому рынку бизнес-клиентов, экономя время и ресурсы.
        </p>
      </div>
      <div class="AboutCompany__toggler">
        <MButton
          :type="MM.EButtonTypes.Text"
          @click="isExpanded = !isExpanded"
        >
          {{ isExpanded ? 'Свернуть' : 'Показать далее' }}
        </MButton>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { MM } from '~/types';

const isExpanded = ref<boolean>(false);
</script>

<style lang="scss">
@import 'styles/base/common/variables';

.AboutCompany {
  // padding: 40px var(--container-offset-X) 80px;
  // // border: 1px solid $color-gray-light;
  // border-radius: 8px;

  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 32px;

  &__main {
  }
  &__title {
    margin-bottom: 16px;
  }
  &__caption {
    color: $color-secondary;
  }
  &__description {
    grid-column: auto / span 2;
  }
  &__text {
  }

  &__toggler {
    margin-top: 8px;
    @include minw(768) {
      display: none;
    }
  }

  @include maxw(1599) {
    padding-bottom: 64px;
    &__caption {
      display: none;
    }
  }

  @include maxw(1279) {
    padding-bottom: 32px;
    padding-top: 32px;
    display: block;
  }

  @include maxw(767) {
    &__text {
      &:not(._expanded) {
        @include clamp(6);
      }
    }
  }
}
</style>
