<template>
  <div class="SliderMainPageFirst">
    <MSlider
      ref="slider"
      :nav-type="ESliderNavType.ArrowsBlock"
      :swiper="{
        speed: 1000,
        slidesPerView: 1,
        effect: 'fade',
        fadeEffect: { crossFade: true },
        autoplay: {
          delay: 6000,
        },
      }"
      :type="ESliderNavType.ArrowsBlock"
      @slider-init="onSliderInit"
    >
      <MSliderItem
        v-for="(item, i) in slides"
        :key="i"
      >
        <div
          class="SliderMainPageFirst__slide"
          :class="`SliderMainPageFirst__slide--${item.isDarkSlide ? 'dark' : 'light'}`"
        >
          <div class="SliderMainPageFirst__bg">
            <MNuxtImg
              :src="item.img.src"
              :sources="item.img.sources"
            />
          </div>

          <div class="SliderMainPageFirst__slide-content">
            <div class="SliderMainPageFirst__slide-body">
              <div class="SliderMainPageFirst__count">
                {{ i + 1 }} из {{ slides.length }}
              </div>
              <div
                class="SliderMainPageFirst__title title-48"
                v-html="item.title"
              />
              <div
                class="SliderMainPageFirst__subtitle"
                v-html="item.subtitle"
              />
            </div>

            <div class="SliderMainPageFirst__slide-footer">
              <MButton
                :icon-right="item.button.iconRight"
                :size="EButtonSizes.Large"
                :theme="item.isDarkSlide ? EButtonThemes.Secondary : EButtonThemes.PrimaryGradient"
                :type="EButtonTypes.Fill"
                @touchend.prevent="item.action"
                @click="item.action"
              >
                {{ item.button.text }}
              </MButton>
            </div>
          </div>
        </div>
      </MSliderItem>
    </MSlider>
  </div>
</template>

<script setup lang="ts">
import ModalManager from 'shared/services/modalManager.service';
import VideoAboutModal from 'components/modals/VideoAboutModal.vue';
import { EButtonSizes, EButtonThemes, EButtonTypes, EIconPath, ESliderNavType } from '@maksmart/maksify/dist/types';
import { useHeaderStore } from 'store/Header.store';

const headerStore = useHeaderStore();

const slides = [
  {
    title: 'B2B-маркетплейс<br> для вашего бизнеса',
    subtitle: 'Инновационная для российского рынка платформа быстрых корпоративных закупок',
    button: {
      text: 'Смотреть видеопрезентацию',
      iconRight: EIconPath.ActionVideoSvg,
    },
    headerIsLight: true,
    img: {
      src: '/images/main/slider-item-1.png',
      srcset:
        '/images/main/slider-item-1-360.png 767w, /images/main/slider-item-1-768.png 1279w, /images/main/slider-item-1-1280.png 1599w',
      sources: [
        { media: '(max-width: 767px)', src: '/images/main/slider-item-1-360.png' },
        { media: '(max-width: 1279px)', src: '/images/main/slider-item-1-768.png' },
        { media: '(max-width: 1599px)', src: '/images/main/slider-item-1-1280.png' },
      ],
    },
    isDarkSlide: false,
    action: () => onShowVideoAboutModal(),
  },
  {
    title: 'Станьте поставщиком<br> для крупнейших компаний России',
    subtitle: 'Откройте прямой доступ к самым надежным и крупным корпоративным клиентам страны',
    button: {
      text: 'Стать поставщиком',
      iconRight: EIconPath.NavigationMainArrowSvg,
    },
    headerIsLight: false,
    img: {
      src: '/images/main/slider-item-2.png',
      srcset:
        '/images/main/slider-item-2-360.png 767w, /images/main/slider-item-2-768.png 1279w, /images/main/slider-item-2-1280.png 1599w',
      sources: [
        { media: '(max-width: 767px)', src: '/images/main/slider-item-2-360.png' },
        { media: '(max-width: 1279px)', src: '/images/main/slider-item-2-768.png' },
        { media: '(max-width: 1599px)', src: '/images/main/slider-item-2-1280.png' },
      ],
    },
    isDarkSlide: true,
    action: () => onSupplierRegistration(),
  },
];

const { onSupplierRegistration } = useSupplierRegistration();

// modal: video about
const videoAboutModalName = 'videoAboutModal';
const modalManager = process.client ? inject<ModalManager>(ModalManager.getServiceName()) : undefined;
function onShowVideoAboutModal(): void {
  modalManager?.show({
    component: VideoAboutModal,
    bind: {
      name: videoAboutModalName,
    },
    on: {
      close() {
        modalManager.hide(videoAboutModalName);
      },
      'update:modelValue'() {
        modalManager.hide(videoAboutModalName);
      },
    },
  });
}

function onSliderInit(_slider) {
  _slider.on('slideChange', (t) => {
    headerStore.theme.isDark = slides[t.activeIndex].isDarkSlide;
  });
}
</script>

<style lang="scss" scoped>
@import 'SliderMainPageFirst';
</style>
