<template>
  <div class="NewsCard">
    <div class="NewsCard__img">
      <BaseImg
        :src="newsService.getImage(props.data.image) || 'images/banner-no-items-icons.svg'"
        format="webp"
        loading="lazy"
      />
    </div>
    <div
      class="NewsCard__title"
      @click="onNewsItemClick"
      v-text="props.data.title"
    />
    <div class="NewsCard__info">
      <div
        v-for="(tag, ndx) in props.data.tags"
        :key="ndx"
        class="NewsCard__tag"
        @click.stop="onNewsTagClick()"
      >
        {{ data.shortSourceUrl ? data.shortSourceUrl : data.tags?.[0]?.label }}
      </div>
      <div
        v-if="props.data.publicationDate"
        class="NewsCard__date"
        v-text="DateFormat.default(props.data.publicationDate)"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { IStrapiNewsListResponse } from 'models/strapi/strapiNews.model';
import { NewsService } from 'services/news/news.service';
import { useRequestUrl } from 'composables/useRequestUrl';
import { sanitizeUrl } from '@braintree/sanitize-url';
import { DateFormat } from 'shared/utils/dateFormat.util';

const props = defineProps<{
  data: IStrapiNewsListResponse;
}>();

const newsService = new NewsService(useRequestUrl());

function onNewsItemClick() {
  if (props.data.shortSourceUrl) {
    window.open(sanitizeUrl(props.data.sourceUrl), '_blank');
    return;
  }

  useRouter().push(`/news/${props.data.id}`);
}

function onNewsTagClick() {
  if (props.data.shortSourceUrl) {
    window.open(sanitizeUrl(props.data.sourceUrl), '_blank');
    return;
  }

  navigateTo(`/news/?categoryId=${props.data.tags?.[0]?.id}`);
}
</script>

<style lang="scss" scoped>
@import 'shared/assets/styles/base/common/variables';

.NewsCard {
  &__img {
    @include cover;
    border-radius: 80px 0;
    aspect-ratio: 360/240;
    margin-bottom: 16px;
    line-height: 0;
  }

  &__title {
    @include text-m20;
    color: $text-dark-green;
    transition: 0.2s;
    cursor: pointer;

    @include hover {
      color: $color-green;
    }
  }

  &__info {
    @include text-r14;
    color: $light-green;
    margin-top: 17px;
    display: flex;
    flex-wrap: wrap;
  }

  &__tag {
    margin-right: 16px;
    position: relative;
    transition: 0.2s;
    cursor: pointer;

    @include hover {
      color: $color-green;
    }

    &:after {
      pointer-events: none;
      position: absolute;
      right: -9px;
      top: 50%;
      transform: translateY(-50%);
      content: '';
      height: 12px;
      width: 1px;
      display: block;
      background-color: $color-gray-dark;
    }
  }

  &__date {
  }
}
</style>
