<template>
  <div class="CompanyAwards">
    <div class="text-m32 CompanyAwards__title">
      Максмарт высоко оценивается в профессиональном комьюнити
    </div>
    <div class="CompanyAwards__list">
      <div
        v-for="(item, i) in list"
        :key="i"
        class="CompanyAwards__item"
        :class="item.isWide ? 'CompanyAwards__item--wide' : 'CompanyAwards__item--default'"
      >
        <div class="CompanyAwards__item-img">
          <BaseImg :src="item.imgSrc" />
        </div>
        <div
          class="CompanyAwards__item-title"
          v-html="item.title"
        />
        <div
          v-if="item.text"
          class="CompanyAwards__item-text text-r16"
          v-html="item.text"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const list: Array<{
  /** Заголовок */
  title: string;
  /** Ссылка на картинку */
  imgSrc: string;
  /** Описание */
  text?: string;
  /** Широкая карточка */
  isWide?: boolean;
}> = [
  {
    title: 'XIII ежегодная Премия<br> «Время инноваций»',
    text: 'Победитель номинации<br> «ТЕХНОЛОГИЧЕСКАЯ ИННОВАЦИЯ ГОДА»<br> в категории «Бизнес и сервис»',
    imgSrc: '/images/company-awards/CompanyAwards-1.png',
    isWide: true,
  },
  {
    title: '«Лучший маркетплейс» Tagline Awards 2023',
    imgSrc: '/images/company-awards/CompanyAwards-2.png',
  },
  {
    title: '«Лучший клиентский опыт в B2B» CX WORLD AWARDS 2024',
    imgSrc: '/images/company-awards/CompanyAwards-3.png',
  },
  {
    title: '«Лучший импортозамещающий сайт» Tagline Awards 2023',
    imgSrc: '/images/company-awards/CompanyAwards-4.png',
  },
  {
    title: '«Лучший маркетплейс» Tagline Awards 2022',
    imgSrc: '/images/company-awards/CompanyAwards-5.png',
  },
  {
    title: 'Международная премия<br> в области создания сильных команд Team Awards',
    text: 'Победитель номинации «ЛИДЕР ГОДА»<br> в категории «Диджитал»',
    imgSrc: '/images/company-awards/CompanyAwards-6.png',
    isWide: true,
  },
];
</script>

<style lang="scss" scoped>
.CompanyAwards {
  $s: &;
  &__title {
    margin-bottom: 32px;
  }
  &__list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 8px;
  }
  &__item {
    background-color: $color-gray-extra-light;
    padding: 32px;
    position: relative;
    height: 360px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;

    &-title {
      @include text-m20;
    }

    &-img {
      :deep(img) {
        max-width: 100%;
        max-height: 100%;
      }
    }
    &-text {
      @include text-r16;
    }

    &--wide {
      padding: 64px 40px;
      padding-right: 320px;
      grid-column: auto / span 2;
      &:before {
        content: '';
        flex-grow: 1;
      }

      #{$s}__item-title {
        @include text-m28;
      }
      #{$s}__item-text {
        margin-top: 20px;
      }

      #{$s}__item-img {
        position: absolute;
        right: 180px;
        transform: translate(50%, 0);
        bottom: 0;
      }
    }
  }

  @include maxw(1599) {
    &__list {
    }
    &__item {
      padding-left: 24px;
      padding-right: 24px;

      &--wide {
        padding-right: 200px;
        #{$s}__item-img {
          right: 100px;
        }
      }
    }
  }
  @include maxw(1279) {
    &__list {
      grid-template-columns: repeat(2, 1fr);
    }
    &__item {
    }
  }
  @include maxw(767) {
    &__list {
      grid-template-columns: repeat(1, 1fr);
    }
    &__item {
      height: auto;
      border-radius: 8px;
      text-align: center;

      &--default {
        padding: 32px 48px;
        #{$s}__item-img {
          margin-bottom: 32px;
        }
      }

      &--wide {
        grid-column: 1 / 2;
        text-align: left;
        padding-bottom: 32px;
        padding-right: 160px;
        min-height: 320px;

        #{$s}__item-img {
          right: 80px;
        }
      }
    }
  }

  // Скругления
  @include minw(1280) {
    &__item:nth-child(1),
    &__item:nth-child(2) {
      border-bottom-right-radius: 120px;
    }
    &__item:nth-child(4) {
      border-top-right-radius: 120px;
    }
    &__item:nth-child(6) {
      border-top-left-radius: 120px;
    }
  }
  @include maxw(1279) {
    @include minw(768) {
      &__item:nth-child(1),
      &__item:nth-child(2) {
        border-bottom-right-radius: 120px;
      }
      &__item:nth-child(5) {
        border-top-left-radius: 120px;
      }
      &__item:nth-child(6) {
        border-top-left-radius: 120px;
      }
    }
  }
}
</style>
